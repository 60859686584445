import { useFrame, useLoader } from "@react-three/fiber";
import React, { useRef } from "react";
import { TextureLoader } from "three";
import texture from "./Assets/linksmap.jpg";
import { Sphere } from "@react-three/drei";

function Earth() {
  // const [widthScreen, setWidthScreen] = useState({
  //   winWidth: window.innerWidth,
  //   earthScale: 1,
  // });
  // useEffect(() => {
  //   if (1200 < widthScreen.winWidth) {
  //     setWidthScreen({ ...widthScreen, earthScale: 2.5 });
  //   } else if (800 < widthScreen.winWidth < 1199) {
  //     setWidthScreen({ ...widthScreen, earthScale: 2 });
  //   } else if (widthScreen.winWidth < 799) {
  //     setWidthScreen({ ...widthScreen, earthScale: 1 });
  //   } else setWidthScreen({ ...widthScreen });
  // }, [widthScreen.winWidth]);
  const planet = useRef();
  const colorMap = useLoader(TextureLoader, texture);
  useFrame(() => (planet.current.rotation.y += 0.002));

  return (
    <Sphere ref={planet} visible args={[1, 100, 200]} scale={2.5}>
      {/* <meshNormalMaterial attach="material" /> */}
      <meshStandardMaterial map={colorMap} />
    </Sphere>
  );
}

export default Earth;

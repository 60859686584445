import React, { useEffect } from "react";
import "./style.css";
import dot from "./Assets/Ellipse.svg";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import RelatedPosts from "../../components/Related Posts/RelatedPosts";
import Newsletter from "../../components/Newsletter/Newsletter";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import shareLink from "./Assets/shareLink.svg";
import linkedin from "./Assets/linkedinAuthor.svg";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import NotFound from "../../components/Not Found/NotFound";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const POST = gql`
  query GetPost($id: ID!) {
    article(id: $id) {
      data {
        id
        attributes {
          Title
          Description
          Date
          ReadingTimeEstimation
          Content
          isFeatured
          YoutubeVideo
          CoverImage {
            data {
              attributes {
                url
              }
            }
          }

          author {
            data {
              id
              attributes {
                Name
                Bio
                Picture {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Linkedin
              }
            }
          }

          category {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
function Post() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { id } = useParams();
  const { loading, error, data } = useQuery(POST, { variables: { id: id } });

  if (loading) return <SpinnerComponent />;
  if (error || data.article.data === null) return <NotFound />;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const datePostTab = data.article.data.attributes.Date.split("-");
  const month = monthNames[datePostTab[1] - 1];
  const Content = { __html: `${data.article.data.attributes.Content}` };
  const currentLocation = window.location.href;
  const YoutubeVideoLink = data.article?.data?.attributes?.YoutubeVideo ? `https://www.youtube.com/embed/${data.article.data.attributes.YoutubeVideo}`: "";


  return (
    <>
      <Helmet>
        <title>{data.article.data.attributes.Title}</title>
        <meta
          name="description"
          content={data.article.data.attributes.Description}
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <main className="container mb-5 pb-5">
        <section className="row mt-5 py-5">
          {/* ----picture and title section----- */}
          <div
            className="container col-11 col-lg-12 "
            style={{
              height: "400px",
              boxShadow: "-4px -4px 16px 0px #0000001A",
              backgroundColor: "white",
              color: "#00022a",
              backgroundImage: `linear-gradient(
              rgba(255, 255, 255, 0.5),
              rgba(255, 255, 255, 0.6)
            ),
            url(${data.article.data.attributes.CoverImage.data.attributes.url})`,

              backgroundSize: "cover",
              border: "none",
              borderRadius: "15px",
            }}
          >
            <div className="row justify-content-center h-100">
              <div className="col-11 col-lg-6 my-auto">
                <h1 className="post_blog_title mx-auto">
                  {data.article.data.attributes.Title}
                </h1>
                <div className="w-100 d-flex justify-content-center">
                  <p className="post_author_name">
                    {data.article.data.attributes.author.data.attributes.Name}
                  </p>
                  <div>
                    <img
                      className="dot mx-2"
                      src={dot}
                      alt="dot"
                      style={{
                        width: "10px",
                        height: "10px",
                      }}
                    />
                    <span className="post_info">{`${month} ${datePostTab[2]}, ${datePostTab[0]}`}</span>
                  </div>

                  <div>
                    <img
                      className="dot mx-2"
                      src={dot}
                      alt="dot"
                      style={{
                        width: "10px",
                        height: "10px",
                      }}
                    />
                    <span className="post_info">{`${data.article.data.attributes.ReadingTimeEstimation} min read`}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <OverlayTrigger
                    key={"left"}
                    placement={"left"}
                    overlay={
                      <Tooltip id={`tooltip-${"left"}`}>
                        Click to copy the post link
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#92140C",
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(currentLocation);
                      }}
                    >
                      <img
                        src={shareLink}
                        style={{ width: "20px", height: "20px" }}
                        alt="share link"
                      />
                    </span>
                  </OverlayTrigger>
                  <span className="mx-2">
                    <FacebookShareButton url={currentLocation}>
                      <FacebookIcon
                        bgStyle={{ fill: "#92140C" }}
                        round={true}
                        size={35}
                      ></FacebookIcon>
                    </FacebookShareButton>
                  </span>

                  <TwitterShareButton url={currentLocation}>
                    <TwitterIcon
                      bgStyle={{ fill: "#92140C" }}
                      round={true}
                      size={35}
                    ></TwitterIcon>
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <section className="row">
            {/* post content */}
            <section
              className="col-12 col-lg-8 text-left post-page-post-section pl-5"
             
            >
              {YoutubeVideoLink && <iframe width="100%" height="450" src={YoutubeVideoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
              <div className="pt-3" dangerouslySetInnerHTML={Content}></div>
            </section>

            {/* aside */}
            <aside className="container col-12 col-lg-4 d-flex flex-column justify-content-between pb-5 aside_section text-light align-items-center py-5">
              {/*-------------author-----------*/}
              <div className="row mt-3 align-self-start">
                <div className="col-11 text-left p-5 ">
                  <h5
                    className="mb-5"
                    style={{
                      borderBottom: "1px solid white",
                    }}
                  >
                    Author
                  </h5>
                  <div className="d-flex">
                    <img
                      src={
                        data.article.data.attributes.author.data.attributes
                          .Picture.data.attributes.url
                      }
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50px",
                      }}
                      alt="hh"
                    />
                    <p className="ml-3 post-author-info my-auto">
                      {`${data.article.data.attributes.author.data.attributes.Name}`}
                    </p>
                  </div>
                  <div>
                    <p className="post-author-bio mt-3">
                      {data.article.data.attributes.author.data.attributes.Bio}
                    </p>
                    <a
                      href={
                        data.article.data.attributes.author.data.attributes
                          .Linkedin
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={linkedin}
                        alt="linkedin author profile"
                        style={{ width: "35px", height: "35px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/*-------------Newsletter------- */}
              <div className="row justify-content-center">
                <div className="col-11">
                  <p className="post-page-title-newsletter">
                    Keep up to date with our latest discoveries by subscribing
                    to our newsletter
                  </p>
                  <Newsletter />
                </div>
              </div>

              <div className="row container text-left justify-content-start mb-5 pb-5">
                <div className="col-10 text-left">
                  <h5
                    className="mb-5"
                    style={{
                      borderBottom: "1px solid white",
                    }}
                  >
                    Related Posts
                  </h5>
                </div>

                {data.article.data.attributes.category.data === null ? null : (
                  <RelatedPosts
                    id={data.article.data.attributes.category.data.id}
                    idPost={id}
                  />
                )}
              </div>
            </aside>
          </section>
        </section>
      </main>
    </>
  );
}

export default Post;

import React, { useState } from "react";
import "./style.css";
import Popup from "../../../components/Popup/Popup";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../../components/Spinner/SpinnerComponent";
import NotFound from "../../../components/Not Found/NotFound";

const ADVISORSINFO = gql`
  query getAdvisorsData {
    advisors {
      data {
        id
        attributes {
          name
          jobTitle
          bio
          linkedin
          picture {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
function Advisors() {
  const { loading, error, data } = useQuery(ADVISORSINFO);

  const [statePropsModal, setStatePropsModal] = useState({});
  // pop-up state mnagement
  const [modalShow, setModalShow] = React.useState(false);
  // end pop-up

  // hover effect
  const [hoverState, setHoverState] = useState({
    r1: 75,
    isHovered1: false,
    r2: 75,
    isHovered2: false,
    r3: 75,
    isHovered3: false,
    r4: 75,
    isHovered4: false,
    r5: 75,
    isHovered5: false,
    r6: 75,
    isHovered6: false,
    r7: 75,
    isHovered7: false,
  });
  if (loading) return <SpinnerComponent />;
  if (error || data.advisors.data === null || data.advisors.data.length === 0)
    return <NotFound />;
  // define the order of advisors
  const advisorsMember1 = data.advisors.data.find((el) => el.id === "1");
  const advisorsMember2 = data.advisors.data.find((el) => el.id === "3");
  const advisorsMember3 = data.advisors.data.find((el) => el.id === "2");
  const advisorsMember4 = data.advisors.data.find((el) => el.id === "4");

  return (
    <div className="container-fluid py-5 px-0 mx-0 mb-5 section-team-container">
      <div className="container py-5">
        <div className="row justify-content-start pb-5">
          <div className="col-12 col-md-9 col-lg-7 d-flex flex-column align-items-start p-0 m-0">
            <p className="first-title-section-team">The ADVISORS</p>
            <h1 className="second-title-section-team">Meet the advisors</h1>
          </div>
        </div>
      </div>

      <div className="row px-0 mx-0">
        <div className="col-12 team_section px-0 mx-0">
          <svg
            version="1.1"
            id="Team"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1401 943"
            style={{ enableBackground: "new 0 0 1401 943" }}
            xmlSpace="preserve"
          >
            <g id="Lines">
              <line
                className="st0"
                x1="555.4"
                y1="332.7"
                x2="315.6"
                y2="73.5"
              />
              <polyline
                className="st0"
                points="407.6,667.5 214.9,796.7 121.6,561.5 407.6,667.5 743.2,532.6 882.1,104.5 	"
              />
              <polyline
                className="st0"
                points="555.4,332.7 120.6,552.7 120.6,552.7 149.7,285.7 	"
              />
              <polyline
                className="st0"
                points="756.9,527.4 1239.1,622.6 890.6,773.3 216.7,793.8 0,541.1 0,541.1 121.3,556.6 0,25.9 311.6,78.4 
		150.3,297.1 	"
              />
              <polyline
                className="st0"
                points="1416.1,564.2 999.4,311.2 745.9,526.2 	"
              />
              <path className="st0" d="M1218.7,277.7" />
              <polyline
                className="st0"
                points="879.2,107 548.6,341.3 612.7,58.1 	"
              />
              <line
                className="st0"
                x1="1406.5"
                y1="121.7"
                x2="1218.7"
                y2="277.7"
              />
              <path className="st0" d="M619.5,24.7" />
              <line
                className="st0"
                x1="1218.7"
                y1="277.7"
                x2="1129"
                y2="45.7"
              />
              <line
                className="st0"
                x1="707.7"
                y1="928.7"
                x2="1157.6"
                y2="908.7"
              />
              <line
                className="st0"
                x1="84.5"
                y1="890.7"
                x2="213.8"
                y2="796.7"
              />
              <line
                className="st0"
                x1="1201.6"
                y1="620.7"
                x2="1406"
                y2="593.7"
              />
              <line
                className="st0"
                x1="555.4"
                y1="332.7"
                x2="404.1"
                y2="662.7"
              />
              <line
                className="st0"
                x1="1302.8"
                y1="852.7"
                x2="1157.6"
                y2="908.7"
              />
              <line
                className="st0"
                x1="1077.4"
                y1="55.7"
                x2="1253.3"
                y2="25.9"
              />
              <line
                className="st0"
                x1="1077.4"
                y1="55.7"
                x2="882.1"
                y2="104.5"
              />
              <line
                className="st0"
                x1="1201.6"
                y1="620.7"
                x2="1157.6"
                y2="908.7"
              />
              <line
                className="st0"
                x1="213.8"
                y1="796.7"
                x2="707.2"
                y2="928.2"
              />
              <line className="st0" x1="865" y1="775.7" x2="707.7" y2="928.7" />
              <line
                className="st0"
                x1="1218.7"
                y1="277.7"
                x2="988.2"
                y2="308.7"
              />
              <line
                className="st0"
                x1="213.8"
                y1="796.7"
                x2="260.4"
                y2="900.2"
              />
              <line
                className="st0"
                x1="749.9"
                y1="522.7"
                x2="667.1"
                y2="627.2"
              />
            </g>
            <path
              className="st1"
              d="M1229.9,199.5c40.9,0,74,33.1,74,74s-33.1,74-74,74s-74-33.1-74-74C1156,232.7,1189.1,199.5,1229.9,199.5
	 M1229.9,198.5c-41.4,0-75,33.6-75,75s33.6,75,75,75s75-33.6,75-75l0,0C1304.9,232.1,1271.4,198.5,1229.9,198.5z"
            />
            <g id="Small-Circles">
              <ellipse className="st2" cx="313.7" cy="72.3" rx="18" ry="17.8" />
              <ellipse
                className="st2"
                cx="121.4"
                cy="560.8"
                rx="18"
                ry="17.8"
              />
              <ellipse className="st2" cx="84.5" cy="887.1" rx="18" ry="17.8" />
              <ellipse
                className="st2"
                cx="702.7"
                cy="919.2"
                rx="20"
                ry="19.8"
              />
              <ellipse
                className="st2"
                cx="1302.8"
                cy="849.5"
                rx="20"
                ry="19.8"
              />
              <ellipse
                className="st2"
                cx="253.4"
                cy="887.1"
                rx="20"
                ry="19.8"
              />
              <ellipse
                className="st2"
                cx="1247.8"
                cy="24.5"
                rx="20"
                ry="19.8"
              />
              <ellipse
                className="st2"
                cx="745.9"
                cy="522.7"
                rx="20"
                ry="19.8"
              />
              <ellipse
                className="st3"
                cx="1003.6"
                cy="312.4"
                rx="23"
                ry="22.8"
              />
              <ellipse className="st3" cx="662.7" cy="626" rx="20" ry="19.8" />
              <ellipse
                className="st3"
                cx="213.8"
                cy="793.8"
                rx="20"
                ry="19.8"
              />
              <ellipse
                className="st3"
                cx="1151.9"
                cy="906.9"
                rx="20"
                ry="19.8"
              />
              <ellipse className="st3" cx="615.2" cy="44.3" rx="20" ry="19.8" />
              <ellipse
                className="st3"
                cx="1123.7"
                cy="44.5"
                rx="20"
                ry="19.8"
              />
            </g>
            {/* ----------------- advisor 1 -----------------------------------------------*/}
            <g>
              <g>
                <pattern
                  id="advisor1"
                  x="0"
                  y="0"
                  width="1"
                  height="1"
                  viewBox="0 0 1024 576"
                  preserveAspectRatio="xMidYMid slice"
                >
                  <image
                    width="1024"
                    height="576"
                    xlinkHref={
                      advisorsMember1.attributes.picture.data.attributes.url
                    }
                  />
                </pattern>
                <circle
                  className="st4 circle"
                  r={hoverState.r1}
                  cx="163.4"
                  cy="273.5"
                  fill="url(#advisor1)"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  onMouseEnter={() =>
                    setHoverState((hoverState) => ({
                      ...hoverState,
                      r1: 100,
                      isHovered1: true,
                    }))
                  }
                  onMouseLeave={() =>
                    setHoverState((hoverState) => ({
                      ...hoverState,
                      r1: 75,
                      isHovered1: false,
                    }))
                  }
                />
              </g>
              {hoverState.isHovered1 && (
                <g>
                  <text
                    transform="matrix(1.4605 0 0 1 268.1118 272.7744)"
                    className="st5 st6"
                  >
                    {advisorsMember1.attributes.name}
                  </text>
                  <text
                    transform="matrix(1.4605 0 0 1 269.6382 302.3359)"
                    className="st7 st6"
                  >
                    {advisorsMember1.attributes.jobTitle}
                  </text>
                  <g
                    onClick={() => {
                      setStatePropsModal({
                        ...advisorsMember1.attributes,
                      });
                      setModalShow(true);
                    }}
                    className="plus"
                  >
                    <g>
                      <circle
                        id="XMLID_00000127015028684095129130000003660067041267180445_"
                        className="st1"
                        cx="283.2"
                        cy="329.7"
                        r="12.5"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle id="SVGID_1_" cx="283.2" cy="329.7" r="12.5" />
                      </defs>
                      <clipPath id="SVGID_00000011727628427740818790000009503340140111785613_">
                        <use
                          xlinkHref="#SVGID_1_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="281.8"
                        y="320.1"
                        style={{
                          clipPath:
                            "url(#SVGID_00000011727628427740818790000009503340140111785613_)",
                          fill: "#1C1C1C",
                        }}
                        width="2.7"
                        height="19.3"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000170968830092026928990000000150613319526184370_"
                          cx="283.2"
                          cy="329.7"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000023264153311472743100000016233651891845430695_">
                        <use
                          xlinkHref="#SVGID_00000170968830092026928990000000150613319526184370_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="273.5"
                        y="328.4"
                        style={{
                          clipPath:
                            "url(#SVGID_00000023264153311472743100000016233651891845430695_)",
                        }}
                        width="19.2"
                        height="2.7"
                      />
                    </g>
                    <g>
                      <circle
                        id="XMLID_00000024690741540625038060000016759995880615562400_"
                        className="st10"
                        cx="283.2"
                        cy="329.7"
                        r="12.5"
                      />
                    </g>
                  </g>
                </g>
              )}
            </g>
            {/* ----------------- advisor 2 -----------------------------------------------*/}
            <g>
              <pattern
                id="advisor2"
                x="0"
                y="0"
                width="1"
                height="1"
                viewBox="0 0 1024 576"
                preserveAspectRatio="xMidYMid slice"
              >
                <image
                  width="1024"
                  height="576"
                  xlinkHref={
                    advisorsMember2.attributes.picture.data.attributes.url
                  }
                />
              </pattern>
              <circle
                className="st4 circle"
                r={hoverState.r2}
                cx="544.4"
                cy="322.7"
                fill="url(#advisor2)"
                stroke="#fff"
                strokeMiterlimit="10"
                onMouseEnter={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r2: 100,
                    isHovered2: true,
                  }))
                }
                onMouseLeave={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r2: 75,
                    isHovered2: false,
                  }))
                }
              />
              {/*-- text advisor 2 --*/}
              {hoverState.isHovered2 && (
                <g>
                  <text
                    transform="matrix(1.4605 0 0 1 663.9697 312.7744)"
                    className="st5 st6"
                  >
                    {advisorsMember2.attributes.name}
                  </text>
                  <text
                    transform="matrix(1.4605 0 0 1 665.4961 342.3359)"
                    className="st7 st6"
                  >
                    {advisorsMember2.attributes.jobTitle}
                  </text>
                  <g
                    onClick={() => {
                      setStatePropsModal({
                        ...advisorsMember2.attributes,
                      });
                      setModalShow(true);
                    }}
                    className="plus"
                  >
                    <g>
                      <circle
                        id="XMLID_00000062172611734624725590000013019906259929616040_"
                        className="st1"
                        cx="679"
                        cy="369.7"
                        r="12.5"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000070818723779087732480000000395653257490972550_"
                          cx="679"
                          cy="369.7"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000020381045388636782020000003270683064162088117_">
                        <use
                          xlinkHref="#SVGID_00000070818723779087732480000000395653257490972550_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="677.6"
                        y="360.1"
                        style={{
                          clipPath:
                            "url(#SVGID_00000020381045388636782020000003270683064162088117_)",
                          fill: "#1C1C1C",
                        }}
                        width="2.7"
                        height="19.3"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000099650774780807880310000011758690557713672836_"
                          cx="679"
                          cy="369.7"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000049208939264776143940000011129600059673578647_">
                        <use
                          xlinkHref="#SVGID_00000099650774780807880310000011758690557713672836_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="669.4"
                        y="368.4"
                        style={{
                          clipPath:
                            "url(#SVGID_00000049208939264776143940000011129600059673578647_)",
                        }}
                        width="19.2"
                        height="2.7"
                      />
                    </g>
                    <g>
                      <circle
                        id="XMLID_00000057827762619108711790000003218689590526743731_"
                        className="st10"
                        cx="679"
                        cy="369.7"
                        r="12.5"
                      />
                    </g>
                  </g>
                </g>
              )}
            </g>
            {/* ----------------- advisor 3 -----------------------------------------------*/}
            <g>
              <pattern
                id="advisor3"
                x="0"
                y="0"
                width="1"
                height="1"
                viewBox="0 0 1024 576"
                preserveAspectRatio="xMidYMid slice"
              >
                <image
                  width="1024"
                  height="576"
                  xlinkHref={
                    advisorsMember3.attributes.picture.data.attributes.url
                  }
                />
              </pattern>
              <circle
                className="st4 circle"
                r={hoverState.r3}
                cx="869.9"
                cy="119.3"
                fill="url(#advisor3)"
                stroke="#fff"
                strokeMiterlimit="10"
                onMouseEnter={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r3: 100,
                    isHovered3: true,
                  }))
                }
                onMouseLeave={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r3: 75,
                    isHovered3: false,
                  }))
                }
              />
              {/*-- text advisor 3 --*/}
              {hoverState.isHovered3 && (
                <g>
                  <text
                    transform="matrix(1.4605 0 0 1 980.1602 119.2744)"
                    className="st5 st6"
                  >
                    {advisorsMember3.attributes.name}
                  </text>
                  <text
                    transform="matrix(1.4605 0 0 1 981.6865 148.8359)"
                    className="st7 st6"
                  >
                    {advisorsMember3.attributes.jobTitle}
                  </text>
                  <g
                    onClick={() => {
                      setStatePropsModal({
                        ...advisorsMember3.attributes,
                      });
                      setModalShow(true);
                    }}
                    className="plus"
                  >
                    <g>
                      <circle
                        id="XMLID_00000073698329324025645920000004849390852263707051_"
                        className="st1"
                        cx="995.2"
                        cy="176.2"
                        r="12.5"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000056416248520129970770000013260269999136050611_"
                          cx="995.2"
                          cy="176.2"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000031181741119780739420000004748692822904561584_">
                        <use
                          xlinkHref="#SVGID_00000056416248520129970770000013260269999136050611_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="993.8"
                        y="166.6"
                        style={{
                          clipPath:
                            "url(#SVGID_00000031181741119780739420000004748692822904561584_)",
                          fill: "#1C1C1C",
                        }}
                        width="2.7"
                        height="19.3"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000109023532449534808110000011525223645775737239_"
                          cx="995.2"
                          cy="176.2"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000133529262458853866140000017714501947153156782_">
                        <use
                          xlinkHref="#SVGID_00000109023532449534808110000011525223645775737239_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="985.6"
                        y="174.9"
                        style={{
                          clipPath:
                            "url(#SVGID_00000133529262458853866140000017714501947153156782_)",
                        }}
                        width="19.2"
                        height="2.7"
                      />
                    </g>
                    <g>
                      <circle
                        id="XMLID_00000047765170826916706720000001961490572947652238_"
                        className="st10"
                        cx="995.2"
                        cy="176.2"
                        r="12.5"
                      />
                    </g>
                  </g>
                </g>
              )}
            </g>
            {/* ----------------- advisor 4 -----------------------------------------------*/}
            <g>
              <pattern
                id="advisor4"
                x="0"
                y="0"
                width="1"
                height="1"
                viewBox="0 0 1024 576"
                preserveAspectRatio="xMidYMid slice"
              >
                <image
                  width="1024"
                  height="576"
                  xlinkHref={
                    advisorsMember4.attributes.picture.data.attributes.url
                  }
                />
              </pattern>
              <circle
                className="st15 circle"
                r={hoverState.r4}
                cx="1218.7"
                cy="258.2"
                fill="url(#advisor4)"
                stroke="#fff"
                strokeMiterlimit="10"
                onMouseEnter={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r4: 100,
                    isHovered4: true,
                  }))
                }
                onMouseLeave={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r4: 75,
                    isHovered4: false,
                  }))
                }
              />
              {/*-- text advisor 4 --*/}
              {hoverState.isHovered4 && (
                <g>
                  <text
                    transform="matrix(1.4605 0 0 1 1223.0762 375.0869)"
                    className="st5 st6"
                  >
                    {advisorsMember4.attributes.name}
                  </text>
                  <text
                    transform="matrix(1.4605 0 0 1 1224.6025 404.6484)"
                    className="st7 st6"
                  >
                    {advisorsMember4.attributes.jobTitle}
                  </text>
                  <g
                    onClick={() => {
                      setStatePropsModal({
                        ...advisorsMember4.attributes,
                      });
                      setModalShow(true);
                    }}
                    className="plus"
                  >
                    <g>
                      <circle
                        id="XMLID_00000015317400575212127670000011870422787997345686_"
                        className="st1"
                        cx="1238.1"
                        cy="432"
                        r="12.5"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000114062050135866766210000017099439341939576987_"
                          cx="1238.1"
                          cy="432"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000000198159069265789270000016338817569401527460_">
                        <use
                          xlinkHref="#SVGID_00000114062050135866766210000017099439341939576987_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="1236.7"
                        y="422.4"
                        style={{
                          clipPath:
                            "url(#SVGID_00000000198159069265789270000016338817569401527460_)",
                          fill: "#1C1C1C",
                        }}
                        width="2.7"
                        height="19.3"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000054977424855046225420000014251387994050402460_"
                          cx="1238.1"
                          cy="432"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000103265348920963304040000015111287146702732204_">
                        <use
                          xlinkHref="#SVGID_00000054977424855046225420000014251387994050402460_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="1228.5"
                        y="430.7"
                        style={{
                          clipPath:
                            "url(#SVGID_00000103265348920963304040000015111287146702732204_)",
                        }}
                        width="19.2"
                        height="2.7"
                      />
                    </g>
                    <g>
                      <circle
                        id="XMLID_00000101076229427919794400000002874075282289788581_"
                        className="st10"
                        cx="1238.1"
                        cy="432"
                        r="12.5"
                      />
                    </g>
                  </g>
                </g>
              )}
            </g>
            {/* ----------------- advisor 5 -----------------------------------------------*/}
            <g>
              <circle className="st4" cx="1183.4" cy="621.2" r="75" />
              {/* <pattern
                id="advisor5"
                x="0"
                y="0"
                width="1"
                height="1"
                viewBox="0 0 1024 576"
                preserveAspectRatio="xMidYMid slice"
              >
                <image
                  width="1024"
                  height="576"
                  xlinkHref={
                    data.advisors.data[4].attributes.picture.data.attributes.url
                  }
                />
              </pattern>
              <circle
                className="st4 circle"
                r={hoverState.r5}
                cx="1183.4"
                cy="621.2"
                fill="url(#advisor5)"
                stroke="#fff"
                strokeMiterlimit="10"
                onMouseEnter={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r5: 100,
                    isHovered5: true,
                  }))
                }
                onMouseLeave={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r5: 75,
                  }))
                }
              /> */}
              {/*-- text advisor 5 --*/}
              {/* {hoverState.isHovered5 && (
                <g>
                  <text
                    transform="matrix(1.4605 0 0 1 1221.8066 734.085)"
                    className="st5 st6"
                  >
                    {data.advisors.data[4].attributes.name}
                  </text>
                  <text
                    transform="matrix(1.4605 0 0 1 1223.332 763.6465)"
                    className="st7 st6"
                  >
                    {data.advisors.data[4].attributes.jobTitle}
                  </text>
                  <g
                    onClick={() => {
                      setStatePropsModal({
                        ...data.advisors.data[4].attributes,
                      });
                      setModalShow(true);
                    }}
                    className="plus"
                  >
                    <g>
                      <circle
                        id="XMLID_00000008134457265038434150000008526162858004161422_"
                        className="st1"
                        cx="1236.8"
                        cy="791"
                        r="12.5"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000085932844305525087480000015655113030915348898_"
                          cx="1236.8"
                          cy="791"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000139976386448424841770000006287753133540061882_">
                        <use
                          xlinkHref="#SVGID_00000085932844305525087480000015655113030915348898_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="1235.5"
                        y="781.4"
                        style={{
                          clipPath:
                            "url(#SVGID_00000139976386448424841770000006287753133540061882_)",
                          fill: "#1C1C1C",
                        }}
                        width="2.7"
                        height="19.3"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000015351811995237604150000009794076778145065142_"
                          cx="1236.8"
                          cy="791"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000023282465754597256810000005479427382475193527_">
                        <use
                          xlinkHref="#SVGID_00000015351811995237604150000009794076778145065142_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="1227.2"
                        y="789.7"
                        style={{
                          clipPath:
                            "url(#SVGID_00000023282465754597256810000005479427382475193527_)",
                        }}
                        width="19.2"
                        height="2.7"
                      />
                    </g>
                    <g>
                      <circle
                        id="XMLID_00000176739659311561172200000016050877890942523037_"
                        className="st10"
                        cx="1236.8"
                        cy="791"
                        r="12.5"
                      />
                    </g>
                  </g>
                </g>
              )} */}
            </g>
            {/* ----------------- advisor 6 -----------------------------------------------*/}
            <g>
              <circle className="st4" cx="418.4" cy="661" r="75" />
              {/* <pattern
                id="advisor6"
                x="0"
                y="0"
                width="1"
                height="1"
                viewBox="0 0 1024 576"
                preserveAspectRatio="xMidYMid slice"
              >
                <image
                  width="1024"
                  height="576"
                  xlinkHref={
                    data.advisors.data[5].attributes.picture.data.attributes.url
                  }
                />
              </pattern>
              <circle
                className="st4 circle"
                r={hoverState.r6}
                cx="418.4"
                cy="661"
                fill="url(#advisor6)"
                stroke="#fff"
                strokeMiterlimit="10"
                onMouseEnter={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r6: 100,
                    isHovered6: true,
                  }))
                }
                onMouseLeave={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r6: 75,
                  }))
                }
              /> */}
              {/*-- text advisor 6 --*/}
              {/* {hoverState.isHovered6 && (
                <g>
                  <text
                    transform="matrix(1.4605 0 0 1 530.6299 692.2217)"
                    className="st5 st6"
                  >
                    {data.advisors.data[5].attributes.name}
                  </text>
                  <text
                    transform="matrix(1.4605 0 0 1 532.1562 721.7832)"
                    className="st7 st6"
                  >
                    {data.advisors.data[5].attributes.jobTitle}
                  </text>
                  <g
                    onClick={() => {
                      setStatePropsModal({
                        ...data.advisors.data[5].attributes,
                      });
                      setModalShow(true);
                    }}
                    className="plus"
                  >
                    <g>
                      <circle
                        id="XMLID_00000031175144552387552570000004229598565650417820_"
                        className="st1"
                        cx="545.7"
                        cy="749.2"
                        r="12.5"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000069360211705107465270000013930332269814012830_"
                          cx="545.7"
                          cy="749.2"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000093153707026111622880000007464294376001953669_">
                        <use
                          xlinkHref="#SVGID_00000069360211705107465270000013930332269814012830_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="544.3"
                        y="739.5"
                        style={{
                          clipPath:
                            "url(#SVGID_00000093153707026111622880000007464294376001953669_)",
                          fill: "#1C1C1C",
                        }}
                        width="2.7"
                        height="19.3"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000067939545425767521940000000019283295928160178_"
                          cx="545.7"
                          cy="749.2"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000075137833501048427050000015534383105932716976_">
                        <use
                          xlinkHref="#SVGID_00000067939545425767521940000000019283295928160178_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="536.1"
                        y="747.8"
                        style={{
                          clipPath:
                            "url(#SVGID_00000075137833501048427050000015534383105932716976_)",
                        }}
                        width="19.2"
                        height="2.7"
                      />
                    </g>
                    <g>
                      <circle
                        id="XMLID_00000138557938617762254290000009686358663631124913_"
                        className="st10"
                        cx="545.7"
                        cy="749.2"
                        r="12.5"
                      />
                    </g>
                  </g>
                </g>
              )} */}
            </g>
            {/* ----------------- advisor 7 -----------------------------------------------*/}
            <g>
              <circle className="st4" cx="848.4" cy="774.5" r="75" />
              {/* <pattern
                id="advisor7"
                x="0"
                y="0"
                width="1"
                height="1"
                viewBox="0 0 1024 576"
                preserveAspectRatio="xMidYMid slice"
              >
                <image
                  width="1024"
                  height="576"
                  xlinkHref={
                    data.advisors.data[6].attributes.picture.data.attributes.url
                  }
                />
              </pattern>
              <circle
                className="st4 circle"
                r={hoverState.r7}
                cx="848.4"
                cy="774.5"
                fill="url(#advisor7)"
                stroke="#fff"
                strokeMiterlimit="10"
                onMouseEnter={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r7: 100,
                    isHovered7: true,
                  }))
                }
                onMouseLeave={() =>
                  setHoverState((hoverState) => ({
                    ...hoverState,
                    r7: 75,
                  }))
                }
              /> */}
              {/*-- text advisor 7 --*/}
              {/* {hoverState.isHovered7 && (
                <g>
                  <text
                    transform="matrix(1.4605 0 0 1 960.6914 808.9746)"
                    className="st5 st6"
                  >
                    {data.advisors.data[6].attributes.name}
                  </text>
                  <text
                    transform="matrix(1.4605 0 0 1 962.2178 838.5361)"
                    className="st7 st6"
                  >
                    {data.advisors.data[6].attributes.jobTitle}
                  </text>
                  <g
                    onClick={() => {
                      setStatePropsModal({
                        ...data.advisors.data[6].attributes,
                      });
                      setModalShow(true);
                    }}
                    className="plus"
                  >
                    <g>
                      <circle
                        id="XMLID_00000117645390303246469260000012963472419237213365_"
                        className="st1"
                        cx="975.7"
                        cy="865.9"
                        r="12.5"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000039818065246455452720000011207824396226556572_"
                          cx="975.7"
                          cy="865.9"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000089546811428039147760000002597805657729142694_">
                        <use
                          xlinkHref="#SVGID_00000039818065246455452720000011207824396226556572_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="974.4"
                        y="856.3"
                        style={{
                          clipPath:
                            "url(#SVGID_00000089546811428039147760000002597805657729142694_)",
                          fill: "#1C1C1C",
                        }}
                        width="2.7"
                        height="19.3"
                      />
                    </g>
                    <g>
                      <defs>
                        <circle
                          id="SVGID_00000021810775919530497470000007542157903626275209_"
                          cx="975.7"
                          cy="865.9"
                          r="12.5"
                        />
                      </defs>
                      <clipPath id="SVGID_00000080922170970692470680000002248382268830674358_">
                        <use
                          xlinkHref="#SVGID_00000021810775919530497470000007542157903626275209_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>

                      <rect
                        x="966.1"
                        y="864.6"
                        style={{
                          clipPath:
                            "url(#SVGID_00000080922170970692470680000002248382268830674358_)",
                        }}
                        width="19.2"
                        height="2.7"
                      />
                    </g>
                    <g>
                      <circle
                        id="XMLID_00000093870211937493583600000004566031384016088764_"
                        className="st10"
                        cx="975.7"
                        cy="865.9"
                        r="12.5"
                      />
                    </g>
                  </g>
                </g>
              )} */}
            </g>
          </svg>
          <>
            <Popup
              show={modalShow}
              onHide={() => setModalShow(false)}
              teamInfo={statePropsModal}
            />
          </>
        </div>
      </div>
    </div>
  );
}

export default Advisors;

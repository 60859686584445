import React, { useEffect, useState } from "react";
import "./style.css";
import { Form } from "react-bootstrap";

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  return (
    <>
      <Form className="bg-transparent" style={{display:'flex',
    width:'100%',
    alignItems:'center',
    height:'min-content',
    justifyContent:'center',
    flexWrap:'wrap'}} onSubmit={(e) => handleSubmit(e)}>
        <div className="row justify-content-center newsletter-input" style={{flex:'1', height:'50px', margin:'1em 0'}}>
          <Form.Group className="col-12" controlId="formBasicEmail" style={{height:'100%'}}>
            <Form.Control
            style={{height:'100%', minWidth:'287px'}}
              className="form_text_input "
              type="email"
              placeholder="Email"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </Form.Group>
        </div>
        <div className="row justify-content-center">
          <div className="col-5 d-flex justify-content-center">
            <button
              className="btn-newsletter"
              type="submit"
              style={{ background: "" }}
            >
              <span className="newsletter-text-button" style={{padding:'1em'}}>Subscribe</span>
            </button>
          </div>
        </div>
      </Form>

      {status === "sending" && (
        <div className="mc__alert mc__alert--sending">sending...</div>
      )}
      {status === "error" && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </>
  );
};

export default CustomForm;

import React from "react";
import { Link } from "react-router-dom";
import  './style.css'

function PostCard({ postImage, postTitle, id }) {
  return (
    <div
      className="col-9 post-card-body col-md-4 col-lg-3 mx-3 px-5 my-5 d-flex flex-column justify-content-end p-3"
      style={{
        zIndex: "2",
        height: "400px",
        transition:'transform 0.2s ease-out',
        color: "#ffffff",
        backgroundImage: `linear-gradient(
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.4)
        ),
        url(${postImage})`,

        backgroundSize: "cover",
        border: "none",
        borderRadius: "55px",
      }}
    >
      <p
        className="post-card-title pb-1"
        style={{
          fontFamily: "Hind",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "22px",
          lineHeight: "131.6%",
        }}
      >
        {postTitle}
      </p>
      <Link
        to={`/posts/${id}`}
        style={{
          textDecoration: "none",
          fontFamily: "Hind",
          fontSize: "15px",
          color: "#ffffff",
        }}
      >
        <p className="">Read More</p>
      </Link>
    </div>
  );
}

export default PostCard;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./style.css";
import { gql, useQuery } from "@apollo/client";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import NotFound from "../../components/Not Found/NotFound";
import Inc from './Inc'
import ParticlesAnimation from "../../components/Particles animation/ParticlesAnimation";

const PRODUCTS = gql`
  query getProducts {
    products {
      data {
        id
        attributes {
          productName
          productDescription
          productPicture {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { loading, error, data } = useQuery(PRODUCTS);
  if (loading) return <SpinnerComponent />;
  if (error || data.products.data === null || data.products.data.length === 0)
    return <NotFound />;
  return (
    <>
      <Helmet>
        <title>Products</title>
        <meta
          name="description"
          content="Apply Machine Learning and Computational Linguistics to identify and quantify trends, evidence changes and predict the future impact by pinpointing winners and losers."
        />
        <link rel="canonical" href="/products" />
      </Helmet>
      <div className="container pb-5">
      <ParticlesAnimation id='p-prod' />
        <div className="row justify-content-center">
          <div className="col-11 col-sm-10 col-md-8 col-xl-6 mt-5 py-5">
            <h1 className="product-page-main-title">DeepSearch by Sectors</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          {data.products.data.map((el) => (
            <div
              className="card col-11  col-xl-10 my-5 text-light"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.6)),url(${el.attributes.productPicture.data.attributes.url})`,
                backgroundSize: "cover",
                border: "none",
                borderRadius: "30px",
              }}
              key={el.id}
            >
              <div className="card-body">
                <h1 className="card-title mt-5 pt-5 product-page-card-title">
                  {el.attributes.productName}
                </h1>
                <p className="card-text text-left p-0 m-0 px-md-5 mx-md-5 my-3 product-page-card-parag">
                  {el.attributes.productDescription}
                </p>
                {/* <button className="product-page-card-button  my-3">
              <span className="product-page-card-button-text">Learn more</span>
            </button> */}
            <div style={{height:'3em'}}/>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Inc/>
      <div style={{ padding:'8em 0',display:'flex', flexDirection:'column', alignItems:'center'}}>
              <div style={{background: 'linear-gradient(90.51deg, #00022a 0%, #92140c 115%)',
                fontSize:'25px',
                '-webkit-background-clip': 'text',
                WebkitTextFillColor: 'transparent'}}>
                To know more about our upcoming projects contact us below
              </div>
              <div style={{height:'3.5em'}}/>
              <NavLink
            className= "bttn"
            style={{textDecoration:'none', borderRadius:'15px', minWidth:'150px', minHeight:'50px'}}
            to="/contact"
          >
            Contact us
          </NavLink>
          <div style={{height:'2em'}}/>
      </div>
    </>
  );
}

export default Products;

import React, { useEffect } from "react";
import "./style.css";
import Hero from "../../website sections/Home Page Sections/Hero/Hero";
import ProductVideo from "../../website sections/Home Page Sections/ProductVideo/ProductVideo";
import OurPartners from "../../website sections/Home Page Sections/OurPartners/OurPartners";
import WhyDeepSearchLabs from "../../website sections/Home Page Sections/WhyDeepSearchLabs/WhyDeepSearchLabs";
import FAQs from "../../website sections/Home Page Sections/FAQs/FAQs";
import Banner from "../../website sections/Home Page Sections/Banner/Banner";
import Contact from "../../website sections/Home Page Sections/Contact/Contact";
import Blog from "../../website sections/Home Page Sections/Blog/Blog";
import Solutions from "../../website sections/Home Page Sections/Solutions/Solutions";
import Newsletter from "../../components/Newsletter/Newsletter";
import { Helmet } from "react-helmet-async";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>DeepSearch Labs</title>
        <meta
          name="description"
          content="Let us answer your questions, quantify the trends, their growth and risk impacts. You focus on what you’re good at."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Hero />
      <OurPartners />
      <ProductVideo />
      <WhyDeepSearchLabs />
      <ProductVideo template="empowers" backGround="white"/>
      <Solutions />
      <FAQs limit={3} />
      <Blog fromHomePage={true} />
      {/*-------------Newsletter------- */}
      <div className="container newsletter-section">
        <div className="row pb-5 mb-5 justify-content-center">
          <div className="col-12">
            <p className="first-title-newsletter">Would you like to always be up to date with our findings?</p>
            <p className="second-title-newsletter">Subscribe to our newsletter</p>
            <div className="newsletter-box"> <Newsletter /> </div>
          </div>
        </div>
      </div>
      {/* <HowItWorks />
      <UseCases />
      <Testimonials />
      <Team />
      <Advisors />
      <Banner /> */}
      <Contact />
    </>
  );
}

export default Home;

import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";

function SpinnerComponent() {
  return (
    <div className="container spinner-container">
      <div className="row justify-content-center h-100">
        <div className="col-12 my-auto">
          <Spinner animation="border" variant="danger" />
        </div>
      </div>
    </div>
  );
}

export default SpinnerComponent;

import React, { useState, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import "./style.css";
import Popup from "../../../components/Popup/Popup";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../../components/Spinner/SpinnerComponent";
import NotFound from "../../../components/Not Found/NotFound";
import ProfileCard from "./ProfileCard";
import TitleBanner from "./TitleBanner";

const TEAMINFO = gql`
  query getData {
    teams {
      data {
        id
        attributes {
          name
          jobTitle
          bio
          linkedin
          Order
          picture {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    advisors {
      data {
        id
        attributes {
          name
          jobTitle
          bio
          linkedin
          Order
          picture {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  
  }
`;

function Team() {
  const { loading, error, data } = useQuery(TEAMINFO);
  const [statePropsModal, setStatePropsModal] = useState({});
  // pop-up state mnagement
  const [modalShow, setModalShow] = React.useState(false);

  const isPc = useMediaQuery({
    minWidth: 1024,
  })
  const openModal = useCallback(
    () => {
      if (!isPc) {
        setModalShow(true);
      }
    },
    [isPc],
  )
  if (loading) return <SpinnerComponent />;
  if (error || data.teams.data === null || data.advisors.data === null || data.teams.data.length === 0 || data.advisors.data.length === 0) return <NotFound />;

  // Clone the data & Sort it by the 'Order' attribute
  const sortedTeams = [...data.teams.data];
  sortedTeams.sort((a, b) => parseInt(a.attributes.Order, 0) - parseInt(b.attributes.Order, 0));

  const sortedAdvisors = [...data.advisors.data];
  sortedAdvisors.sort((a, b) => parseInt(a.attributes.Order, 0) - parseInt(b.attributes.Order, 0));

  return (
    <div className="container-fluid py-5 px-0 mx-0 mb-5 section-team-container">
      <div className="container py-5" style={{ paddingLeft: '4em' }}>
        <div className="row justify-content-start pb-5">
          <div className="col-12 col-md-9 col-lg-7 d-flex flex-column align-items-start p-0 m-0">
            <p className="first-title-section-team">The TEAM</p>
            <h1 className="second-title-section-team">Meet the team</h1>
          </div>
        </div>
      </div>

      <div className="column px-0 mx-0" style={{ display: 'flex', flexDirection: 'column' }}>

        <div style={{ display: 'flex', marginBottom: '3em' }}>
          <TitleBanner title='Team' />
          <div className="team_section">
            {sortedTeams.map((data, i) => <ProfileCard key={i} actions={{ openModal: openModal, updateModal: () => setStatePropsModal(data.attributes) }} {...(data)} />)}
          </div>
        </div>
        <div style={{ display: 'flex', margin: '1em 0' }}>
          <TitleBanner title='Advisory Board' />
          <div className="team_section" >
            {sortedAdvisors.map((data, i) => <ProfileCard key={i} actions={{ openModal: openModal, updateModal: () => setStatePropsModal(data.attributes) }} {...(data)} />)}
          </div>
          {!isPc && <Popup
            show={modalShow}
            onHide={() => setModalShow(false)}
            teamInfo={statePropsModal}
          />}
        </div>
      </div>
    </div>
  );
}

export default Team;

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Footer from "./components/Footer/Footer";
import Products from "./pages/Products/Products";
import About from "./pages/About/About";
import Blog from "./pages/Blog/Blog";
import Post from "./pages/Post/Post";
import Solution from "./pages/Solution/Solution";
import SolutionsPage from "./pages/Solution/SolutionsPage";
import FaqsPage from "./pages/Faq/FaqsPage";
import { HelmetProvider } from "react-helmet-async";

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

//link
const httpLink = createHttpLink({
  uri: "https://website.deepsearchlabs.com/graphql",
});
//cache
const cache = new InMemoryCache();
//apollo client
const client = new ApolloClient({
  link: httpLink,
  cache,
});

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ApolloProvider client={client}>
          <div className="App">
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/faq" element={<FaqsPage />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/posts/:id" element={<Post />} />
              <Route exact path="/solutions" element={<SolutionsPage />} />
              <Route exact path="/solution/:id" element={<Solution />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />
            {/* {isIOS || isMobile ? null : <ParticlesAnimation />} */}
          </div>
        </ApolloProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;

import React, { useState } from "react";
import "./style.css";
import { Tab, Nav } from 'react-bootstrap';


function ProductVideo({template = "features", backGround = "black"}) {
  const [activeMainTab, setActiveMainTab] = useState('tab0');
  const [activeTab, setActiveTab] = useState(0);

  let data = [];
  let title = "";
  let title2 = "";
  let title3 = "";
  let style = {};
  // let style = backGround == "black" ? { backgroundColor: "#00000d", color: "#e6e6e6"} : {};
  
  if (template == "empowers") {
    title = "Deepsearch Labs empowers your team with Data.";
    title2 = "Data";
    title3 = "Don't struggle with Data";
    data = [
      {category: 'Internal data', info: [
        {title: 'Internal raw data', decription: "Scan company presentation, Sell side research reports, memos, consultancy reports, your own research reports, meeting notes, audio transcripts, for need to know takeaways. Never spent hours looking for a material again. Dump all raw data in a folder on your desktop or through an SFTP."},
        {title: 'Models, spreadsheets, and structured data', decription: "Extract insights from your company internal models, databases, trackers, and other data sets instantaneously. Generate new analysis with the ability to look through your files in seconds. Dump your data in a folder on your desktop or through an SFTP."},
        {title: 'Databases and Data Lakes', decription: "We collect from different sources, and do linking and create a main connected knowledge space. Its never easier to keep track of your information."},
      ]},
      {category: 'DSL corpus of data', info: [
        {title: 'Filings, Earnings, investor transcripts', decription: "Analyse filings, investors calls, and transcripts, including blogs and press releases. Never miss a document or disclosure again."},
        {title: 'Market data and fundamentals', decription: "Access real time market data, and public company fundamentals, through our application and get analysis on that in less than a minute."},
        {title: 'Top-tier News and Media Sources', decription: "Collect relevant information and current developments from premier financial sources. We dig through the links so you dont have to."},
        {title: 'Social Media', decription: "Collec all relevant information from our social media databases related to your topic of research seamlessly. We get rid of the noise so you can focus on quality content."},
        {title: 'Podcasts', decription: "You can go through podcasts data and see if you can find any expert opinion on the information you are researching."},
        {title: 'Expert Opinions', decription: "With our extensive expert opinion datasets, you can never miss any information related to your topic of interest."},
        {title: 'Everything on the web', decription: "Search on all real time web results, never stale or lagging. With our citation and attribution interface, you can select the sources you trust."},
        {title: '3rd party data connections', decription: "We aim to partner with data vendors you are already using to intergrate their content into our intelligence platform to seamlessly look through their and generate reports, all powered by our AI tools."},
      ]},
    ];
  } else if (template == "features") {
    title = "Deepsearch Labs enables your team by providing a complete suite of data engineering, algorithms, and enterprise-ready application.";
    title2 = "Services";
    title3 = "What do we offer?";
    data = [
      {category: 'Application', info: [
        {title: 'Finding Hidden Patterns', decription: "Use our proprietary knowledge graph to uncover hidden patterns and insights from your data and ours, helping your team stay ahead of market trends and make informed decisions."},
        {title: 'Multimodal Insight Discovery', decription: "Seamlessly discover, analyse, and integrate insights from Excel files, PDFs, videos, and audio formats to gather the information you need intuitively from your internal data or our corpus of online data. Everything is referenced."},
        {title: 'Private Scraper', decription: "Search all available online information on any topic of your choice with a private scraper."},
        {title: 'Multimodal Content Generation', decription: "Create text, reports, videos, and podcasts tailored to your organisation's needs, including research papers, presentations, market reports, and automated insights."},
        {title: 'Trend Scoping', decription: "Predict and identify emerging trends in real time with our AI-driven analytics including sentiment analysis, providing you with forward-looking market intelligence."},
      ]},
      
      {category: 'Scrapers', info: [
        {title: 'PDF Scraper', decription: "We have written scrapers for all kinds of data to make them compatible with our LLMs and system for our application. You are now able to use them for your projects. We do this alongside consulting to ensure best results. <br/>Our PDF scraper reads the PDF the way a human does. It is an ensemble of vision and texts models, built in house. The scraper interprets tables and graphs also."},
        {title: 'Audio scraper with speaker mapping', decription: "We have written scrapers for all kinds of data to make them compatible with our LLMs and system for our application. You are now able to use them for your projects. We do this alongside consulting to ensure best results. <br/>Our audio scraper, transcribe audio, provides sentiment analysis on audio file and maps speakers accurately."},
        {title: 'Web Scraper', decription: "We have written scrapers for all kinds of data to make them compatible with our LLMs and system for our application. You are now able to use them for your projects. We do this alongside consulting to ensure best results. <br/>Our generalised crawler can crawl the Web for any information you require. From websites to generic search on keyword, the can adapt to any format."},
      ]}, 
      
      {category: 'Algorithm and Synthesis', info: [
        {title: 'Report Generation', decription: "If you already have got data  you can use our algorithms for different purposes. Same algorithms are used in our application. We provide this with consultation. <br/>We  have multiagent  compounding LLMs, that can provide you with ondemands report generation."},
        {title: 'Sentiment signals', decription: "If you already have got data  you can use our algorithms for different purposes. Same algorithms are used in our application. We provide this with consultation. <br/>We can provide you with sentiment signals across all our corpus of online data and your internal data on any keywords regardless of type going back 5 years."},
      ]}, 
      
      {category: 'Consulting services', info: [
        {title: 'Automate Workflow', decription: "Using our algorithms and scrapers automate workflow for your teams and create customized application. We can help you create best in house software."},
        {title: 'Data Engineering', decription: "Linking all your data in one place regardless of type and location of the data. <br/>Building and maintaining scraper pipelines to keep you updated: PDF scraping, audio, web based. <br/>Optimizing complex internal data for better search capabilities. <br/>Creating vector and graph databases for effective AI use."},
      ]}, 
    ];
  }
  
  return (

    <div className="container-fluid x3_section py-5" style={style}>

      <div className="container py-4">
        
        <div className="row justify-content-start py-2 mb-1">
          <div className="align-items-start">
            <p className="first-title text-left">{title2}</p>
            <h1 className="second-title text-left">{title3}</h1>
            <p style={{textAlign: 'left', fontWeight: '600'}}>{title}</p>
          </div>
        </div>

        <Tab.Container>

          <Nav variant="tabs" onSelect={(selectedKey) => { setActiveMainTab(selectedKey); setActiveTab(0); } }>
            
            {data.map((value, index) => (
              <Nav.Item>
                <Nav.Link eventKey={'tab'+index} active={activeMainTab === 'tab'+index}>
                  {value.category}
                </Nav.Link>
              </Nav.Item>
            ))}
            
          </Nav>

          <Tab.Content>
            {data.map((value, index) => (
              <Tab.Pane eventKey={'tab'+index} active={activeMainTab === 'tab'+index}>
                <div className="box-row">
                  {value.info.map((v, i) => (
                    <div className={activeTab == i ? 'box fixed-box active' : 'box fixed-box'} onClick={() => setActiveTab(i)}>
                      <span>{v.title}</span>
                    </div>
                  ))}
                  <div className="box remaining-box">
                    {activeMainTab === 'tab'+index && <div>
                      <h1>{value.info[activeTab].title}</h1>
                      <div key={index} dangerouslySetInnerHTML={{ __html: value.info[activeTab].decription }} />
                    </div>}
                  </div>
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>

        </Tab.Container>
    
      </div>

    </div>
  )
}

export default ProductVideo;

import React, { Suspense } from "react";
import "./style.css";
import { Canvas } from "@react-three/fiber";
import Earth from "../../../components/Earth/Earth";
import { OrbitControls } from "@react-three/drei";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
import arrow from "./Assets/arrow.svg";
import short_tour from "./Assets/short_tour2.gif";

function Hero() {
  return (
    <>
      <div className="container-fluid hero_section pb-3 py-md-0" style={{ position: 'relative' }}>
        {/* <ParticlesAnimation id='p-hero' /> */}
        <div className="row justify-content-center h-100 px-0 py-5 px-md-3 px-lg-5">
          <div className="col-12 col-md-11 col-lg-5 col-xl-6 order-1 order-lg-1 container-fluid my-auto mx-auto pt-5">
            <div className="row justify-content-center">
              <div className="col-10 mx-auto">
                <h1 className="text-md-left main-heading main_title_hero">
                  AI powered Intelligence Platform
                </h1>
                <p className="text-left main_para_hero">
                  Deepsearch Labs is a secure, enterprise-grade AI-driven intelligence platform specializing in knowledge discovery, analysis, and synthesis of both internal and online data -- Save time, uncover insights, connect information, and automate workflows.
                </p>
              </div>
            </div>
            <div className="row mx-auto mt-3">
              <div className="col-10 mx-auto d-flex" style={{ padding: '0' }}>
                <a className="hero_buttonkk red_btn" href="#contact-section">
                  Try it now
                </a>
                {/* <a
                  className="hero_buttonkk white_btn align-self-center mx-3"
                  href="#how-it-works"
                >
                  Learn more
                </a> */}
              </div>
            </div>
            <div className="row justify-content-center pt-4">
              <div className="col-10 mx-auto">
                <p className="text-left main_para_hero mb-1">
                  <img className="header-right-arrow" src={arrow} />
                  Visualize hidden patterns across millions of sources
                </p>
                <p className="text-left main_para_hero mb-1">
                  <img className="header-right-arrow" src={arrow} />
                  Multimodal Knowledge Search, Analysis, and Synthesis
                </p>
                <p className="text-left main_para_hero mb-1">
                  <img className="header-right-arrow" src={arrow} />
                  Research report in minutes
                </p>
                <p className="text-left main_para_hero mb-1">
                  <img className="header-right-arrow" src={arrow} />
                  Videos and Podcasts Generation in minutes
                </p>
              </div>
            </div>
          </div>
          <div className="col-11 col-md-10 col-lg-7 col-xl-6 order-2 order-lg-2 text-center align-items-center d-flex p-5 short_tour">
            <img className="img_short_tour" src={short_tour} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;

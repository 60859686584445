import React from "react";
import "./style-inc.css";
import ParticlesAnimation from "../../components/Particles animation/ParticlesAnimation";
import tshirt from './Assets/shirt.svg'
import sbag from './Assets/shopping.svg'
import paint from './Assets/paint.svg'
function Inc() {
  return (
    <>
      <div
      style={{position:'relative',
        filter:'drop-shadow(-10px -10px 20px rgba(0,0,0,0.5))'}}
        className="container-fluid inc_section py-5 mt-5"
        id="inc-this-year"
      >
              <ParticlesAnimation id='p-inc' />
        <div style={{height:'2em'}}/>
        <h1 className="light_title">Comming in 2022</h1>
        <div style={{height:'3em'}}/>
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', height:'fit-content'}}>
            <div style={{width:'268px', display:'flex', flexDirection:'column', alignItems:'center', padding:'2.5em', backgroundColor:'#fdfafa', borderRadius:'20px',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex:'99',  margin:'1em', color:'#00022a'}}>
                <img src={tshirt} width='60px' alt="tshirt icon"/>
                <div style={{height:'2em'}}/>
                <div style={{fontSize:'25px', fontWeight:'bold'}} >DeepSearch  for Retail investors</div>
                <div style={{height:'2em'}}/>
                <div style={{border:'1px solid #92140c', width:'141px'}}/>
                <div style={{height:'2em'}}/>
                <div style={{textAlign:'start'}}>
                Everything you need, to be as good as institutional investors, visualize trends, be up to date, a new way to do research
                </div>
            </div>
            <div style={{width:'268px', display:'flex', flexDirection:'column', alignItems:'center', padding:'2.5em', backgroundColor:'#fdfafa', borderRadius:'20px',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex:'99',  margin:'1em', color:'#00022a'}}>
                <img src={sbag} width='60px' alt="shopping bag icon"/>
                <div style={{height:'2em'}}/>
                <div style={{fontSize:'25px', fontWeight:'bold'}} >DeepSearch  for Fashion</div>
                <div style={{height:'2em'}}/>
                <div style={{border:'1px solid #92140c', width:'141px'}}/>
                <div style={{height:'2em'}}/>
                <div style={{textAlign:'start'}}>
                Everything you need from Brand Intelligence to finding new products and supply chain management
                </div>
            </div>
            <div style={{width:'268px', display:'flex', flexDirection:'column', alignItems:'center', padding:'2.5em', backgroundColor:'#fdfafa', borderRadius:'20px',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex:'99',  margin:'1em', color:'#00022a'}}>
                <img src={paint} width='60px' alt="paint icon"/>
                <div style={{height:'2em'}}/>
                <div style={{fontSize:'25px', fontWeight:'bold'}} >DeepSearch  for Cosmetics</div>
                <div style={{height:'2em'}}/>
                <div style={{border:'1px solid #92140c', width:'141px'}}/>
                <div style={{height:'2em'}}/>
                <div style={{textAlign:'start'}}>
                Everything you need from Brand Intelligence to finding new products and supply chain management
                </div>
            </div>
        </div>
        <div style={{height:'2em'}}/>
      </div>
    </>
  );
}

export default Inc;

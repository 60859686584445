import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Logo from "./Assets/logo_footer.svg";
import linkedin from "./Assets/linkedin.svg";
import twitter from "./Assets/twitter.svg";
import reddit from "./Assets/reddit.png";
import youtube from "./Assets/youtube.png";

function Footer() {
  return (
    <footer className="container-fluid footer_section pt-5">
      <div className="footer-elements">
        <div className="col-5 col-md-3 col-lg-2 footer_column">
          <img
            className="img-fluid logo_footer"
            src={Logo}
            alt="Deepsearch Labs logo"
          />
        </div>
        <div className="col-5 col-md-3 col-lg-2 text-left footer_column">
          <h4 className="footer_column_title pt-2 pb-3 ">LINKS</h4>
          <ul className="links">
            <li>
              <Link className="list-item" to="/home">
                Home
              </Link>
            </li>
            <li>
              {" "}
              <Link className="list-item" to="/solutions">
                Solutions
              </Link>
            </li>
            <li>
              {" "}
              <Link className="list-item" to="/blog">
                Resources
              </Link>
            </li>
            <li>
              {" "}
              <Link className="list-item" to="/faq">
                FAQ
              </Link>
            </li>
            <li>
              <Link className="list-item" to="/about">
                About
              </Link>
            </li>
          </ul>
        </div>

        <div className="col-5 col-md-3 col-lg-2 text-left footer_column">
          <h4 className="footer_column_title pt-2 pb-3 ">CONTACT</h4>
          <div>
            {/* <div>info@deepsearchlabs.com</div> */}
            <div>
              <Link className="list-item" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="col-5 col-md-3 col-lg-2 text-left footer_column">
          <h4 className="footer_column_title pt-2 pb-3 ">SOCIAL</h4>
          <div>
            <a
              href="https://www.linkedin.com/company/deepsearch-labs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social_media_icon"
                src={linkedin}
                alt="Linkedin"
              />
            </a>
            <a
              href="https://www.reddit.com/r/Unbiased_Views/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social_media_icon"
                src={reddit}
                alt="Reddit"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCfLx-E6WYwo4oX77IJG-8Yw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social_media_icon"
                src={youtube}
                alt="Youtube"
              />
            </a>
            <a
              href="https://twitter.com/DeepsearchLabs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social_media_icon" src={twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-5 pb-2 pt-5">
        <div className="col-8 col-md-4">
          <p className="text-light">© 2022 by DeepSearch labs</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, {useMemo} from "react";
import "./style.css";
import Icon1 from "./Assets/icon1.svg";
import Icon2 from "./Assets/icon2.svg";
import Icon3 from "./Assets/icon3.svg";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
function UseCases() {
  return (
    <>
      <div style={{position:'relative'}}  className="container-fluid mt-5">
        <ParticlesAnimation id='p-cases' />
        <div className="container py-5">
          <div className="row justify-content-start">
            <div className="col-12 col-md-9 col-lg-10 d-flex flex-column align-items-start p-0">
              <p className="first-title">USE CASES</p>
              <h1 className="second-title text-left">
                The platform can be used for different objectives
              </h1>
            </div>
          </div>
          <div className="row mt-5 pt-3 justify-content-center">
            <div className="col-10 col-sm-9 col-md-6 col-lg-4 my-3">
              <div className="card bg-transparent border-0 px-3 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon1}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Ideation, research, evidencing theories
                  </h5>
                  {/* <p className="card-text">
                    
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-10 col-sm-9 col-md-6 col-lg-4  my-3">
              <div className="card bg-transparent border-0 px-3 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon2}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">Product launches</h5>
                  {/* <p className="card-text"></p> */}
                </div>
              </div>
            </div>
            <div className="col-10 col-sm-9 col-md-6 col-lg-4 my-3">
              <div className="card bg-transparent border-0 px-3 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon3}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Due Diligence and Market research
                  </h5>
                  {/* <p className="card-text"></p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UseCases;

import React, { useEffect } from "react";
import "./style.css";
import { useQuery, gql } from "@apollo/client";
import BlogSection from "../../website sections/Home Page Sections/Blog/Blog";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import NotFound from "../../components/Not Found/NotFound";
import { Helmet } from "react-helmet-async";

const ARTICLES = gql`
  query GetArticles {
    articles {
      data {
        id
        attributes {
          Title
          Description
          isFeatured
          CoverImage {
            data {
              attributes {
                url
              }
            }
          }
          author {
            data {
              attributes {
                Name
                Picture {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // get data
  const { loading, error, data } = useQuery(ARTICLES);
  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;
  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content="Let us answer your questions, quantify the trends, their growth and risk impacts. You focus on what you’re good at. 
          Keep up to date with our latest discoveries"
        />
        <link rel="canonical" href="/blog" />
      </Helmet>
      <BlogSection align="center" />
    </>
  );
}

export default Blog;

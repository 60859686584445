import React, { useEffect, useState } from "react";
import "./style.css";
import dot from "./Assets/Ellipse.svg";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import RelatedPosts from "../../components/Related Posts/RelatedPosts";
import Newsletter from "../../components/Newsletter/Newsletter";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import shareLink from "./Assets/shareLink.svg";
import linkedin from "./Assets/linkedinAuthor.svg";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import NotFound from "../../components/Not Found/NotFound";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import UseCases from "../../website sections/Home Page Sections/Use Cases/UseCases";
import Solutions from "../../website sections/Home Page Sections/Solutions/Solutions";
import { Link } from "react-router-dom";


const POST = gql`
  query GetSolution($id: ID!) {
    solution(id: $id) {
      data {
        id
        attributes {
          name
          desc
          JSON_Tab
          icon {
            data {
              attributes {
                url
              }
            }
          }
          
          articles {
            data {
              id
              attributes {
                Title
                Date
                Description
                CoverImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                
                category {
                  data {
                    attributes {
                      Category
                    }
                  }
                }

              }
            }
          }

        }
      }
    }
  }
`;

function Solution() {
  const [activeTab, setActiveTab] = useState(0);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { id } = useParams();
  const { loading, error, data } = useQuery(POST, { variables: { id: id } });

  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;

  // The initial description string
  let desc = data?.solution?.data?.attributes?.desc || "";
  // JSON_Tab
  let JSON_Tab = data?.solution?.data?.attributes?.JSON_Tab || [];

  // Function to render the custom table
  const renderCustomTable = (index = 0) => (
    <div className="box-row">
      {JSON_Tab[index].map((v, i) => (
        <div
          key={i}
          className={activeTab === i ? 'box fixed-box active' : 'box fixed-box'}
          onClick={() => setActiveTab(i)}
        >
          <span>{v.title}</span>
        </div>
      ))}
      <div className="box remaining-box">
        {JSON_Tab[index] && JSON_Tab[index][activeTab] && <div>
          <h1>{JSON_Tab[index][activeTab].title}</h1>
          <div dangerouslySetInnerHTML={{ __html: JSON_Tab[index][activeTab].decription }} />
        </div>}
      </div>
    </div>
  );

  // Replace 'JSON_Tab' in the description with the custom table rendered by JSX
  const renderDescription = () => {
    const parts = desc.split('JSON_Tab');
    return (
      <div>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            <span dangerouslySetInnerHTML={{ __html: part }} /> {/* Render regular text */}
            {index < parts.length - 1 && renderCustomTable(index)} {/* Insert table at 'JSON_Tab' */}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
  }

  const articlesData = data.solution?.data?.attributes?.articles?.data || [];
  // Sort the array by Date
  const sortedArticles = articlesData.slice().sort((a, b) => {
      const dateA = new Date(a.attributes.Date);
      const dateB = new Date(b.attributes.Date);
      return dateB - dateA;
  });

  return (
    <>
      <Helmet>
        <title>{data.solution.data.attributes.name}</title>
        <meta
          name="description"
          content={data.solution.data.attributes.desc}
        />
        <link rel="canonical" href={pathname} />
      </Helmet>

      <main className="container-fluid m-0 py-5 single-solution">
        <div className="container">

          <section className="row mt-5">
            <div className="col-md-12 text-center">
              <img
                className="solution-logo m-4"
                src={data.solution.data.attributes.icon?.data?.attributes?.url}
                alt="Card cap"
              />
              <h1>{data.solution.data.attributes.name}</h1>
            </div>
            {renderDescription()}
          </section>

          {sortedArticles.length > 0 && <h1 className="p-4 text-center">Case Studies</h1 >}
          <div className="row case_study_blogs">
            {sortedArticles.map(article => (
              <div className="col-md-4 my-3 text-left" key={article.id}>
                <img
                  className="img-rounded blog-imgs"
                  src={article.attributes.CoverImage?.data?.attributes?.url}
                  alt="Card cap"
                />
                <p className="category-text">{article.attributes.Title}</p>
                <p className="article-tilte">{article.attributes.category.data.attributes.Category}</p>
                <p className="article-date">{article.attributes.Date}</p>
                <p className="article-desct">{truncateText(article.attributes.Description, 100)}</p>
                <Link to={"/posts/" + article.id} style={{ textDecoration: "none" }}>
                  <p className="article-read-more">
                    Read more
                  </p>
                </Link>
              </div>
            ))}
          </div>

        </div>
      </main>

      <Solutions />

    </>
  );
}

export default Solution;

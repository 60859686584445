import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

const RELATEDPOSTS = gql`
  query GetCategory($id: ID!) {
    category(id: $id) {
      data {
        attributes {
          Category
          articles {
            data {
              id
              attributes {
                Title
                CoverImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                ReadingTimeEstimation
                author {
                  data {
                    attributes {
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function RelatedPosts(props) {
  const id_exclude = props.idPost;
  const { loading, error, data } = useQuery(RELATEDPOSTS, {
    variables: { id: props.id },
  });
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  const postsTab =
    data.category.data.attributes.articles.data.length > 2
      ? data.category.data.attributes.articles.data.slice(1, 3)
      : data.category.data.attributes.articles.data;

  return (
    <>
      {postsTab
        .filter((el) => el.id !== id_exclude)
        .map((el) => (
          <Link
            to={`/posts/${el.id}`}
            className="col-12 my-2 d-flex"
            key={el.id}
            style={{
              textDecoration: "none",
              fontFamily: "Hind",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "134.6%",
              color: " #FFFFFF",
            }}
          >
            <img
              className="my-auto"
              src={el.attributes.CoverImage.data.attributes.url}
              alt=""
              style={{ width: "80px", height: "90px" }}
            />
            <div className="my-auto text-left ml-3">
              <p className="py-0 my-0" style={{ fontSize: "12.5px" }}>
                {`${el.attributes.ReadingTimeEstimation} min read`}
              </p>
              <p className="py-0 my-0">{el.attributes.Title}</p>
              <p className="py-0 my-0" style={{ fontWeight: "500" }}>
                By {el.attributes.author.data.attributes.Name}
              </p>
            </div>
          </Link>
        ))}
    </>
  );
}

export default RelatedPosts;

import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";

function Banner() {
  return (
    <section style={{position:'relative'}} className="container-fluid banner_section">
            <ParticlesAnimation id='p-banner' />
      <div className="row w-100 h-100">
        <div className="col-12 col-md-11 col-lg-9 mx-auto my-auto">
          <p className="first-title-banner">
            Join the many satisfied users today and start building your future!
          </p>
          <Link to="/contact">
            <button className="my-5 banner-btn">Request a Demo</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Banner;

import React from "react";
import "./style.css";

function NotFound() {
  return (
    <div className="container notfound-page-container">
      <div className="row justify-content-center">
        <div className="col-10 my-5 py-5">
          <p style={{ fontFamily: "Hind", fontSize: "150px" }}>404</p>
          <p style={{ fontFamily: "Hind", fontSize: "75px" }}>
            Content Not Found
          </p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;

import React from "react";
import "./style.css";
import ContactForm from "../../../components/Contact Form/ContactForm";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";

function Contact() {
  return (
    <section
      style={{position:'relative'}}
      className="container-fluid contact_section justify-content-center"
      id="contact-section"
    >
      {/* <ParticlesAnimation id='p-contact' /> */}
      <div className="row h-100 ">
        <div className="container" style={{color: '#fff', margin: '60px auto 18px', fontSize: '36px', fontWeight: 900}}>Join the many satisfied users today</div>
        <div className="container" style={{color: '#fff', margin: '0 auto 60px ', fontSize: '26px'}}>Let us show you how Deepsearch Labs can empower your organization to unlock the full potential of your data. Reach out to our team for a personalized consultation and demo.</div>
        <div className="col-11 col-md-7 col-lg-6 col-xl-5 mx-auto my-auto rounded">
          <ContactForm />
        </div>
      </div>
    </section>
  );
}

export default Contact;

import React, {useMemo} from "react";
import "./style.css";
import arrow from "./Assets/arrow.png";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../../components/Spinner/SpinnerComponent";
import NotFound from "../../../components/Not Found/NotFound";
import { Link } from "react-router-dom";

const POSTS = gql`
  query GetFaqs {
    faqs {
      data {
        id
        attributes {
          Title
          Text
          Order
        }
      }
    }
  }
`;

function FAQs({limit = 0, align = 'left'}) {
  const { loading, error, data } = useQuery(POSTS);

  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;

  // Clone the data array
  const sortedData = [...data.faqs.data];

  // Sort the cloned array by the 'Order' attribute
  sortedData.sort((a, b) => a.attributes.Order - b.attributes.Order);

  const FAQslimit = parseInt(limit, 0);
  let FAQsData  = sortedData.length ? FAQslimit > 0 ? sortedData.slice(0, FAQslimit) : sortedData : [];

  return (
    <>
      {FAQsData.length && <div style={{position:'relative'}}  className="container-fluid mt-5">
        {/* <ParticlesAnimation id='p-cases' /> */}
        <div className="container py-5 faqs">
          <div className="row justify-content-start">
            <div className={"col-12 p-0 text-"+align}>
              <p className="first-title">FAQs</p>
              <h1 className="second-title">Why DeepSearch Labs?</h1>
            </div>
          </div>
          <div className="row mt-4 pt-3">
            <div className="container-fluid text-left">
              <div className="accordion" id="accordionExample">
                {FAQsData.map((el, index) => (<div className="card">
                  <div className="card-header" id2="headingOne" id={"heading-" + index}>
                    <h2 className="mb-0">
                      <a className="btn text-left" type="button" data-toggle="collapse" data-target={"#collapse-" + index} aria-expanded={false} aria-controls={"collapse-" + index}>
                        <img
                          className="card-img-top-left icons"
                          src={arrow}
                          alt="Card cap"
                        />
                        {el.attributes.Title}
                      </a>
                    </h2>
                  </div>
                  <div id={"collapse-" + index} className={"collapse"} aria-labelledby={"heading-" + index} data-parent="#accordionExample">
                    <div className="card-body text-left">
                      {el.attributes.Text}
                    </div>
                  </div>
                </div>))}
              </div>
            </div>
          </div>
          {FAQslimit > 0 && <Link to={"/faq"} style={{ textDecoration: "none", color: "#000" }}>
            <p className="text-left pt-3">
              Visit our FAQ page for more questions.
            </p>
          </Link>}
        </div>
      </div>}
    </>
  );
}

export default FAQs;

import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";

const Newsletter = (props) => {
  //postUrl
  const postUrl = `https://deepsearchlabs.us14.list-manage.com/subscribe/post?u=3dd5dbee6381545fddc4d1dc8&id=4269400bf1`;

  return (
    <div className="mx-auto">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default Newsletter;

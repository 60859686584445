import React, { useEffect } from "react";
import "./style.css";
import Team from "../../website sections/Home Page Sections/Team and Advisors/Team";
import { Helmet } from "react-helmet-async";
import Advisors from "../../website sections/Home Page Sections/Team and Advisors/Advisors";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="DeepSearch labs is a deeptech company, focused on creating specialized and bespoke intelligent search engines for industry. We are where insight meets search engines.
          We collect data from the World Wide Web, label, organize, and de-noise it, all without human interaction. 
          We simplify the world by helping clients understand what information matters, we summarize the information into reports and identify trends, their stages of development and predict its future impact while highlighting winners and losers.
          Our output enhances decision making, idea generation, research, product creation, and risk mitigation. We remove the expense of data-sets from our clients and allow them to go straight to the insight."
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <div>
        <div className="container mt-5 pt-5">
          <div className="row justify-content-center">
            <div className="col-10 col-md-6">
              <h1 className="about-page-main-title">About Us</h1>
            </div>
          </div>
        </div>
        <div className="container-fluid story_section">
          <div className="row justify-content-center py-5">
            <div className="col-8 text-left text-light py-5">
              <h1 className="my-3 about-page-story-title">Making complex research simpler, better, and faster.</h1>
              <p className="about-page-parag">
                Deepsearch Labs is a deeptech company that was started in 2020. We offer a cutting-edge intelligence platform that combines ML-enhanced search and multimodal analysis and synthesis. By seamlessly integrating internal data—whether uploaded, pushed through the cloud, or connected via API access points—with our corpus of online data sources such as media, social media, company reports, podcasts, and videos, we provide users with a comprehensive and unbiased map of information on any topic. This map that is based on structured and unstructured data, is contextualized using generative algorithms in various formats, including but not limited to research reports, presentations, videos and podcasts, enabling users to make informed, data-driven decisions with greater confidence.
              </p>
              <p className="about-page-parag">
                Our platform enables our clients to do advance analysis, on large amount of data, in minutes.  DSL platform is ever evolving with feedback from clients, and more sophisticated features are built regularly through working with the market. Today, we’re proud to be at the forefront of the Generative AI revolution from Report creation to Video and podcast generation. Our aim is revolutionaize how people interact with internal and online data, from search to analysis and synthesis.
              </p>
              <p className="about-page-parag">
                We know that we are ahead of market can do, and as a result we offer consulting services and sell our algorithms to enable companies to build cutting edge data pipelines, databases and enable them to become ready for the AI revolution.
              </p>
              <p className="about-page-parag">
                With a team of experts from leading finance and technology companies, we made it our mission to deliver the world’s most powerful, accurate, and secure AI in a product purpose-built industries that utilize large quantities of data. To test our product, please do not hesitate to <a href="/contact">get in touch</a>.
              </p>
            </div>
          </div>
        </div>
        {/* <Team /> */}
        {/* <Advisors /> */}
      </div>
    </>
  );
}

export default About;

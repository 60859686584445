import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import logoNav from "./Images/logoNav.svg";
import icon from "./Images/toggle-icon.svg";

function Header() {
  const [classToggle, setClassToggle] = useState({ dtog: "", dtarg: "" });
  useEffect(() => {
    if (window.innerWidth < 992) {
      setClassToggle({ dtog: "collapse", dtarg: "#navbarTogglerDemo02" });
    } else {
      setClassToggle({ dtog: "", dtarg: "" });
    }
  }, []);

  return (
    <nav className="navbar navbar-expand-lg px-md-5 overflow-hidden">
      <NavLink className="navbar-brand px-5" to="/">
        <img className="logo-navbar" src={logoNav} width='189px' alt="DeepSearch Labs Logo" />
      </NavLink>
      <button
        className="navbar-toggler mr-3"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon">
          <img
            src={icon}
            alt="toggle button"
            style={{ width: "27px", height: "40px" }}
          />
        </span>
      </button>

      <ul
        className="navbar-nav mr-auto collapse navbar-collapse justify-content-end "
        id="navbarTogglerDemo02"
      >
        <li
          className="nav-item"
          data-toggle={classToggle.dtog}
          data-target={classToggle.dtarg}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-style nav-link" : "nav-link"
            }
            to="/"
          >
            Home
          </NavLink>
        </li>
        <li
          className="nav-item"
          data-toggle={classToggle.dtog}
          data-target={classToggle.dtarg}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-style nav-link" : "nav-link"
            }
            to="/solutions"
          >
            Solutions
          </NavLink>
        </li>
        <li
          className="nav-item"
          data-toggle={classToggle.dtog}
          data-target={classToggle.dtarg}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-style nav-link" : "nav-link"
            }
            to="/blog"
          >
            Resources
          </NavLink>
        </li>
        <li
          className="nav-item"
          data-toggle={classToggle.dtog}
          data-target={classToggle.dtarg}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-style nav-link" : "nav-link"
            }
            to="/faq"
          >
            FAQ
          </NavLink>
        </li>
        <li
          className="nav-item"
          data-toggle={classToggle.dtog}
          data-target={classToggle.dtarg}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-style nav-link" : "nav-link"
            }
            to="/contact"
          >
            Contact
          </NavLink>
        </li>
        <li
          className="nav-item"
          data-toggle={classToggle.dtog}
          data-target={classToggle.dtarg}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-style nav-link" : "nav-link"
            }
            to="/about"
          >
            About
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-style bttn" : "bttn"
            }
            to="/contact"
          >
            Try it now
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
